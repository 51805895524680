import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text, Strong, Span, Image, Button, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				my architect help | expertise within reach
			</title>
			<meta name={"description"} content={"We help you answer all your questions regarding architecture, design and construction issues."} />
			<meta property={"og:title"} content={"my architect help | expertise within reach"} />
			<meta property={"og:description"} content={"We help you answer all your questions regarding architecture, design and construction issues."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1621109246687-10ae613f2d8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/apple-touch-icon.png?v=2024-07-10T21:30:27.429Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-512x512.png?v=2024-07-10T21:30:58.923Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section padding="16px 40px 16px 40px" quarkly-title="Header" position="absolute" sm-padding="16px 20px 16px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
				width="100%"
				max-width="none"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Components.Logo />
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel z-index="1">
					<Override
						slot="Content"
						justify-content="flex-end"
						padding="0px 0px 0px 0px"
						background="none"
						sm-background="rgba(233, 233, 230, 0.79)"
						sm-overflow="visible"
					/>
					<Override
						slot="Button Text"
						md-text-transform="uppercase"
						md-color="--darkL2"
						md-letter-spacing="1px"
						md-font="--base"
					/>
					<Override slot="Button Icon" md-color="--darkL2" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="#contact"
							text-decoration-line="initial"
						>
							Contact
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" quarkly-title="HeroBlock" background="linear-gradient(0deg,rgba(249, 248, 243, 0.2) 0%,rgba(249, 248, 243, 0.6) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1621109246687-10ae613f2d8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover repeat scroll padding-box" sm-padding="100px 20px 100px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="column"
				min-height="80vh"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0px 0px 0"
				color="--darkL2"
				font="--headline1"
				width="70%"
				lg-font="--headline1Lg"
				sm-font="--headline2Lg"
				sm-width="100%"
				display="flex"
				flex="0 0 auto"
				lg-width="auto"
			>
				expertise
				<br />
				within
				<br />
				reach
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				flex-wrap="no-wrap"
				align-items="flex-end"
				justify-content="flex-start"
				grid-row-gap="1rem"
				lg-align-self="auto"
				lg-flex-direction="row"
				lg-align-items="flex-start"
				lg-display="flex"
				lg-justify-content="flex-end"
				lg-flex="1"
			>
				<Text
					sm-margin="0px 0px 0px 0px"
					margin="0px 0px 0px 0"
					color="--darkL2"
					sm-text-align="left"
					font="--headline3"
					text-align="right"
					lg-font="--headline3Lg"
					sm-width="100%"
				>
					<Span
						font-weight="normal"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							ARCHITECTURE
						</Strong>
						<br />
					</Span>
					<Span
						font-weight="normal"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						CONSULTING
						<br />
					</Span>
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Architecture Consulting Services">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-flex-direction="column"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				sm-min-width="280px"
			/>
			<Text
				color="--darkL2"
				font="--headline2"
				width="20%"
				text-align="right"
				lg-font="--headline2Lg"
				lg-width="100%"
				lg-text-align="left"
				sm-margin="16px 0px 0px 0px"
				min-width="min-content"
				margin="0 0px 16px 0px"
			>
				welcome to{" "}
				<Span
					font-weight="normal"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					my architect help
				</Span>
			</Text>
			<Image
				src="https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
				display="block"
				width="60%"
				sm-width="100%"
				sm-order="-1"
				srcSet="https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
			/>
			<Box width="20%" lg-width="35%" sm-width="100%">
				<Text
					color="--darkL2"
					margin="0px 0px 12px 0px"
					font="--lead"
					lg-width="100%"
					sm-width="100%"
				>
					<Strong>
						Architecture Consulting Services
					</Strong>
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 12px 0px"
					font="--lead"
					lg-width="100%"
					sm-width="100%"
				>
					We specialise in
					<br />
					{" "}providing top-tier architectural consulting services tailored to your needs.
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 12px 0px"
					font="--lead"
					lg-width="100%"
					sm-width="100%"
				>
					Our team of experienced architects and designers is here to guide you through every step of your project, ensuring that your vision is brought to life with precision and excellence.
					<br />
					Explore our range of services below.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" quarkly-title="General Questionaire" sm-display="block">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				width="100%"
				max-width="none"
				flex-wrap="wrap"
				sm-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box
				width="100%"
				display="flex"
				grid-gap="20px"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="no-wrap"
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-align-items="flex-start"
				md-display="flex"
				md-flex-direction="row"
				md-justify-content="flex-start"
				justify-content="flex-start"
			>
				<Image
					display="block"
					width="60%"
					sm-width="100%"
					md-overflow="visible"
					md-height="100%"
					background="rgba(0, 0, 0, 0) url() 0% 0% /cover repeat scroll padding-box"
					height="100%"
					sm-min-height="300px"
					src="https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					srcSet="https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					height="100%"
					justify-content="space-between"
					lg-order="-1"
					sm-width="100%"
					sm-height="auto"
					md-width="36%"
					md-display="flex"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-align-items="flex-start"
					md-justify-content="flex-start"
					md-grid-row-gap="0px"
					width="40%"
				>
					<Text
						color="--darkL2"
						margin="0px 0px 50px 10px"
						font="--headline2"
						width="100%"
						text-align="left"
						position="relative"
						lg-margin="0px 0px 50px 0px"
						lg-font="--headline2Lg"
						sm-margin="36px 0px 0px 0px"
					>
						general questionaire
					</Text>
					<Image
						src="https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						width="100%"
						sm-order="-1"
						md-height="auto"
						md-min-height="initial"
						srcSet="https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1449247526693-aa049327be54?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="flex-start"
				lg-justify-content="flex-start"
				lg-width="100%"
				lg-display="flex"
				lg-grid-column-gap="5%"
				lg-flex-direction="row"
				lg-flex-wrap="no-wrap"
				sm-display="flex"
				sm-flex-direction="column"
				sm-flex-wrap="no-wrap"
				sm-grid-row-gap="30px"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-grid-row-gap="20px"
				grid-column-gap="20px"
				flex="1 1 0%"
				align-items="flex-start"
				flex-direction="row"
			>
				<Text
					color="--darkL2"
					margin="20px 0px 0px 0px"
					font="--lead"
					max-width="360px"
					lg-max-width="540px"
					sm-margin="0px 0px 0px 0px"
					lg-flex="0 1 auto"
					align-self="auto"
				>
					Start your journey with us by completing our general questionnaire. This crucial step helps us understand your requirements, preferences, and expectations. Whether you’re planning to move to a new apartment, a new house, a renovation, or an investment, our questionnaire ensures we have all the necessary details to provide you with personalised and effective solutions.{"\n\n\n"}
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					lg-align-items="center"
					lg-display="flex"
					lg-flex-direction="column"
					lg-justify-content="center"
					lg-flex-wrap="no-wrap"
					lg-grid-row-gap="20px"
					flex="1 1 0%"
					align-self="center"
					align-items="center"
					display="flex"
					flex-direction="column"
					justify-content="center"
					flex-wrap="no-wrap"
					grid-row-gap="20px"
				>
					<Text margin="0px 0px 0px 0px" lg-text-align="center" text-align="center" font="--lead">
						Reach us out at not cost.
						<br />
						By sending us your questions you are one step closer to{" "}
						<br />
						understand how to solve your issues.
					</Text>
					<Button
						type="link"
						href="/#contact"
						background="#2e2e2e"
						font="normal 400 20px/1.2 --fontFamily-googlePoppins"
						text-decoration-line="initial"
					>
						CONTACT US
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Design and Space Organisation Issues">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				max-width="none"
				width="100%"
				lg-flex-direction="row"
				lg-align-items="flex-start"
				sm-min-width="280px"
				lg-display="flex"
				lg-flex-wrap="no-wrap"
				lg-justify-content="center"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
			/>
			<Image
				src="https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
				display="block"
				lg-order="-1"
				sm-width="100%"
				srcSet="https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1611269154421-4e27233ac5c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				lg-display="flex"
				lg-align-items="flex-end"
				lg-justify-content="center"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-grid-row-gap="40px"
				width="100%"
				order="-1"
				max-width="600px"
				display="flex"
				flex-direction="column"
				flex-wrap="no-wrap"
				grid-row-gap="40px"
				align-items="flex-end"
				md-max-width="initial"
			>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--headline2"
					text-align="right"
					lg-font="--headline2Lg"
					sm-margin="16px 0px 0px 0px"
					lg-width="100%"
					md-text-align="left"
				>
					DESIGN AND
					<br />
					SPACE
					<br />
					ISSUES
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					lg-width="100%"
					lg-max-width="540px"
					lg-align-self="auto"
					sm-width="100%"
					max-width="300px"
					flex-direction="column"
					md-max-width="initial"
				>
					<Strong>
						Design and Space Organisation Issues
						<br />
					</Strong>
					<br />
					Struggling with design and space organisation? Our experts are here to help. We offer insightful analysis and innovative solutions to optimise your space. From layout improvements to aesthetic enhancements, we ensure your environment is functional, beautiful, and tailored to your needs. Let us transform your space into a harmonious and efficient setting.{"\n\n\n"}
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Property Aquisition">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				width="100%"
				max-width="none"
				lg-flex-direction="row"
				lg-align-items="flex-end"
				sm-min-width="280px"
				lg-display="flex"
				lg-flex-wrap="no-wrap"
				sm-display="flex"
				sm-flex-direction="column"
				sm-flex-wrap="no-wrap"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="50%"
				grid-gap="20px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				sm-width="100%"
				md-width="100%"
			>
				<Image
					src="https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					display="block"
					width="100%"
					srcSet="https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1496865534669-25ec2a3a0fd3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
			</Box>
			<Box
				width="40%"
				lg-width="100%"
				lg-display="flex"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				lg-height="100%"
				md-height="auto"
			>
				<Text
					color="--darkL2"
					margin="0px 0px 50px 10px"
					font="--headline2"
					text-align="left"
					lg-width="100%"
					lg-margin="0px 0px 20px 0px"
					lg-font="--headline2Lg"
					sm-margin="16px 0px 20px 0px"
				>
					PROPERTY ACQUISITION
					<br />
					—
					<br />
					THINKING OF ACQUIRING A PROPERTY?
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					width="60%"
					lg-max-width="540px"
					lg-width="100%"
					md-height="initial"
					md-max-width="initial"
				>
					Our consultants provide thorough evaluations to help you make informed decisions. We assess the quality of potential properties, estimate renovation costs, and identify any hidden issues, by collaborating with local partners . Our goal is to ensure that your investment is sound and aligns with your long-term plans, saving you time and money in the process.{"\n\n\n"}
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				max-width="none"
				width="100%"
				lg-flex-direction="row"
				lg-align-items="flex-end"
				lg-display="flex"
				lg-flex-wrap="no-wrap"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				sm-height="min-content"
				md-align-items="flex-start"
				md-justify-content="center"
				md-align-self="flex-start"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="40%"
				grid-row-gap="40px"
				display="flex"
				flex-direction="column"
				flex-wrap="no-wrap"
				sm-width="100%"
				md-align-self="flex-start"
				md-width="100%"
				lg-width="100%"
				lg-height="100%"
				sm-display="flex"
				sm-flex-direction="column"
				sm-flex-wrap="no-wrap"
				sm-align-items="center"
				sm-justify-content="flex-start"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="flex-start"
			>
				<Text
					color="--darkL2"
					font="--headline2"
					width="auto"
					text-align="right"
					lg-width="100%"
					lg-text-align="left"
					lg-font="--headline2Lg"
					min-width="min-content"
					margin="0px 0px 16px 0px"
					display="block"
				>
					INVESTMENT CONSULTATION
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					width="70%"
					align-self="flex-end"
					lg-width="100%"
					lg-max-width="540px"
					lg-align-self="auto"
					md-max-width="inherit"
				>
					Investing in real estate requires careful consideration and expertise. We offer specialised consultation services to evaluate the artistic value, historical significance, and overall quality of properties. Whether you’re interested in tourism, commercial ventures, or luxury estates, our team ensures your investment is sustainable and valuable, and enhances quality of life.
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					grid-row-gap="20px"
					flex-direction="column"
					font="--lead"
					width="70%"
					align-items="flex-start"
					display="flex"
					align-self="flex-end"
					lg-width="auto"
				>
					<Text margin="0px 0px 0px 0px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Artistic Value
							<br />
						</Strong>
						We assess the architectural significance and aesthetic appeal of properties, ensuring they hold lasting value.
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Historical and Quality Surveys
							<br />
						</Strong>
						Our thorough surveys provide detailed insights into the history and quality of properties, helping you make well-informed decisions.
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Sustainability
							<br />
						</Strong>
						We prioritise sustainable practices and materials, promoting eco-friendly and energy-efficient solutions.
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Quality of Life
							<br />
						</Strong>
						Our designs focus on enhancing the quality of life for occupants, ensuring comfort, functionality, and well-being.
					</Text>
				</List>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				width="60%"
				lg-height="100%"
				lg-width="100%"
				md-order="-1"
			>
				<Image
					display="block"
					width="118%"
					lg-order="-1"
					sm-width="100%"
					overflow="hidden"
					md-width="100%"
					lg-width="100%"
					background="rgba(0, 0, 0, 0) url() 0% 0%/cover scroll"
					lg-height="100%"
					src="https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					srcSet="https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				flex-wrap="no-wrap"
				max-height="1200px"
				max-width="1280px"
				align-content="flex-start"
				lg-flex-direction="row"
				lg-flex-wrap="no-wrap"
				lg-width="100%"
				lg-max-width="none"
				lg-align-items="center"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-justify-content="space-between"
				lg-display="flex"
				sm-height="min-content"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="100%"
				overflow-x="hidden"
				overflow-y="hidden"
				align-self="center"
				order="0"
				lg-height="100%"
				md-align-self="center"
				md-flex="1 1 0%"
			>
				<Image
					display="block"
					lg-order="-1"
					sm-width="100%"
					md-align-self="flex-start"
					md-width="100%"
					width="137%"
					src="https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					height="100%"
					position="relative"
					left="-16%"
					lg-width="60%"
					md-left={0}
					srcSet="https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1589200412874-288748d561dc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				font="--lead"
				width="40%"
				md-height="auto"
				md-width="100%"
				md-overflow="visible"
				display="flex"
				flex-direction="column"
				flex-wrap="no-wrap"
				grid-row-gap="30px"
			>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="auto"
					text-align="left"
					order="-1"
					lg-width="100%"
					lg-font="--headline2Lg"
					min-width="min-content"
					align-self="flex-start"
				>
					SECTORS WE SERVE
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					grid-row-gap="20px"
				>
					<Text margin="0px 0px 0px 0px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Tourism
							<br />
						</Strong>
						We provide tailored solutions related to eco-tourism, ensuring exceptional guest experiences.
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Companies
							<br />
						</Strong>
						Our corporate solutions enhance productivity, brand image, and employee satisfaction through strategic design and space planning.
					</Text>
					<Text margin="0px 0px 0px 0px">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							High-Net-Worth Individuals
							<br />
						</Strong>
						We offer bespoke services for luxury estates and high-end investments, ensuring every detail reflects your lifestyle and status.
					</Text>
				</List>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				width="100%"
				max-width="none"
				sm-min-width="280px"
				display="flex"
				flex-direction="row"
				flex-wrap="no-wrap"
				grid-column-gap="50px"
				sm-display="flex"
				sm-flex-direction="column"
				sm-flex-wrap="no-wrap"
				sm-grid-row-gap="30px"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				justify-content="center"
				md-grid-row-gap="30px"
			/>
			<Image
				width="40%"
				md-width="100%"
				lg-width="60%"
				md-background="rgba(0, 0, 0, 0) url() 50% 50% /cover repeat scroll padding-box"
				src="https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
				srcSet="https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				flex-wrap="no-wrap"
				grid-row-gap="40px"
				sm-display="flex"
				sm-grid-row-gap="20px"
				sm-overflow="visible"
				width="30%"
				md-display="flex"
				md-grid-row-gap="100%"
				md-width="100%"
				justify-content="flex-start"
				align-items="flex-start"
			>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--headline2"
					text-align="left"
					lg-width="100%"
					lg-font="--headline2Lg"
					md-font="--headline3"
				>
					CONNECT WITH US
				</Text>
				<Text
					color="--darkL2"
					margin="20px 0px 0px 0px"
					font="--lead"
					lg-width="100%"
					lg-max-width="540px"
					width="auto"
				>
					We encourage you to contact us without any strings attached. If you are facing a situation, fill out the form and let us know. We will help you understand your options, and should you want, our team can send you a budget proposal on the proposed course of action.
					<br />
					<br />
					We can also connect you with local specialists with whom we network.
					<br />
					<br />
					You decide on what to do, with no hidden fees, all costs discussed upfront and incurred only upon your approval.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-green" sm-padding="40px 20px 40px 20px">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box
				flex-wrap="no-wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				lg-align-items="center"
				lg-display="flex"
				lg-flex-direction="row"
				lg-flex-wrap="no-wrap"
				lg-justify-content="flex-start"
				flex-direction="row"
				align-items="center"
				justify-content="flex-start"
			>
				<Text
					font="normal 600 18px/1.5 --fontFamily-googleInter"
					color="--light"
					margin="0px 0px 0px 0px"
					width="10%"
					lg-width="10%"
				>
					01
				</Text>
				<Text
					font="--headline2"
					color="--lightD1"
					margin="0px 0px 0px 0px"
					width="90%"
					md-font="--headline3"
					sm-font="normal 700 24px/1.1 &quot;Inter&quot;, sans-serif"
					lg-font="--headline2Lg"
					lg-width="90%"
				>
					Fill out the form
				</Text>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="no-wrap"
				margin="0px 0px 46px 0px"
				lg-align-items="center"
				lg-display="flex"
				lg-flex-direction="row"
				lg-flex-wrap="no-wrap"
				lg-justify-content="flex-start"
				align-items="center"
				flex-direction="row"
				justify-content="flex-start"
			>
				<Text
					color="--light"
					margin="0px 0px 0px 0px"
					font="normal 600 18px/1.5 --fontFamily-googleInter"
					width="10%"
					lg-width="10%"
				>
					02
				</Text>
				<Text
					color="--lightD1"
					margin="0px 0px 0px 0px"
					width="90%"
					md-font="--headline3"
					sm-font="normal 700 24px/1.1 &quot;Inter&quot;, sans-serif"
					font="--headline2"
					lg-font="--headline2Lg"
					lg-width="90%"
				>
					Get a preliminary answer
				</Text>
			</Box>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="no-wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				lg-align-items="center"
				lg-display="flex"
				lg-flex-direction="row"
				lg-justify-content="flex-start"
				lg-flex-wrap="no-wrap"
				align-items="center"
				flex-direction="row"
				justify-content="flex-start"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="normal 600 18px/1.5 --fontFamily-googleInter"
					color="--light"
					width="10%"
					lg-width="10%"
				>
					03
				</Text>
				<Text
					font="--headline2"
					color="--lightD1"
					margin="0px 0px 0px 0px"
					width="90%"
					md-font="--headline3"
					sm-font="normal 700 24px/1.1 &quot;Inter&quot;, sans-serif"
					lg-font="--headline2Lg"
					lg-width="90%"
				>
					Review and discuss costs: We'll inform you of any potential expenses before proceeding
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="no-wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				lg-display="flex"
				lg-flex-direction="row"
				lg-flex-wrap="no-wrap"
				lg-align-items="center"
				lg-justify-content="flex-start"
				align-items="center"
				flex-direction="row"
				justify-content="flex-start"
			>
				<Text
					font="normal 600 18px/1.5 --fontFamily-googleInter"
					color="--light"
					margin="0px 0px 0px 0px"
					width="10%"
					lg-width="10%"
				>
					04
				</Text>
				<Text
					md-font="--headline3"
					sm-font="normal 700 24px/1.1 &quot;Inter&quot;, sans-serif"
					font="--headline2"
					color="--lightD1"
					margin="0px 0px 0px 0px"
					width="90%"
					lg-font="--headline2Lg"
					lg-width="90%"
				>
					Accept the budget: Confirm your approval to move forward with services, only then...
				</Text>
			</Box>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="no-wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				lg-display="flex"
				lg-flex-direction="row"
				lg-flex-wrap="no-wrap"
				lg-align-items="center"
				lg-justify-content="flex-start"
				align-items="center"
				flex-direction="row"
				justify-content="flex-start"
			>
				<Text
					font="normal 600 18px/1.5 --fontFamily-googleInter"
					color="--light"
					margin="0px 0px 0px 0px"
					width="10%"
					lg-width="10%"
				>
					05
				</Text>
				<Text
					sm-font="normal 700 24px/1.1 &quot;Inter&quot;, sans-serif"
					font="--headline2"
					color="--lightD1"
					margin="0px 0px 0px 0px"
					width="90%"
					md-font="--headline3"
					lg-font="--headline2Lg"
					lg-width="90%"
				>
					Incur expenses (if applicable): Pay for services once you've been informed and agreed upon the cost.
				</Text>
			</Box>
		</Section>
		<Section align-items="center" display="flex" background="--color-green">
			<Override slot="SectionContent" align-items="center" />
			<Components.MainForm height="100vh" width="100%" display="flex" />
		</Section>
		<Section padding="100px 40px 100px 40px" sm-padding="40px 20px 40px 20px" quarkly-title="Footer" background="--color-green">
			<Override
				slot="SectionContent"
				md-align-items="center"
				flex-direction="row"
				md-flex-direction="column"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-align-items="flex-start"
				sm-min-width="280px"
			/>
			<Box width="100%" margin="0px 0px 100px 0px">
				<Components.Logo />
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				align-items="start"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="repeat(1, 1fr)"
			>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					md-justify-content="center"
					md-align-items="center"
					md-padding="16px 16px 16px 16px"
					md-width="100%"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					sm-align-items="flex-start"
					sm-padding="16px 0px 16px 0px"
					margin="0px 24px 0px 0px"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						color="--darkL2"
						sm-text-align="center"
						text-transform="uppercase"
						letter-spacing="1px"
					>
						About us
					</Text>
					<Link
						href="/about-us"
						color="--darkL2"
						font="--base"
						margin="0px 0px 0px 0px"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
						display="none"
					>
						Our Story
					</Link>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
						display="none"
					>
						Career
					</Link>
					<Link
						href="/privacy-policy"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						Privacy Policy
					</Link>
					<Link
						href="/terms-and-conditions"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						Terms and Conditions
					</Link>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					md-justify-content="center"
					md-align-items="center"
					md-padding="16px 16px 16px 16px"
					md-width="100%"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					sm-align-items="flex-start"
					sm-padding="16px 0px 16px 0px"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						color="--darkL2"
						sm-text-align="center"
						text-transform="uppercase"
						letter-spacing="1px"
					>
						CONTACTS
					</Text>
					<Link
						href="mailto:info@myarchitecthelp.com"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="left"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						info@myarchitecthelp.com
					</Link>
					<Link
						href="tel:2087432000"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="left"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					/>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="left"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					/>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					md-justify-content="center"
					md-align-items="center"
					md-padding="16px 16px 16px 16px"
					md-width="100%"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="none"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					sm-align-items="flex-start"
					sm-padding="16px 0px 16px 0px"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						color="--darkL2"
						sm-text-align="center"
						text-transform="uppercase"
						letter-spacing="1px"
					>
						FOLLOW US
					</Text>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						WhatsApp
					</Link>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						LinkedIn
					</Link>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						Instagram
					</Link>
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});